<template>
  <div class="mx-5 mx-md-16 px-md-16">
    <v-data-table
      :headers="headers"
      :items="users.items"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      :search="search"
      hide-default-footer
      class="elevation-1 rounded-lg"
      @page-count="pageCount = $event"
    >
      <template v-slot:item.actions="{ item }">
        <router-link
          custom
          v-slot="{ navigate }"
          :to="{ name: 'EditAdmin', params: { id: item.idNumber } }"
        >
          <v-btn icon @click="navigate"
            ><v-icon small color="orange">fa-edit</v-icon></v-btn
          >
        </router-link>
        <v-btn icon @click="deleteUser(item)"
          ><v-icon small color="red">fa-trash</v-icon></v-btn
        >
      </template>
    </v-data-table>
    <div class="pt-2 d-flex flex-column flex-md-row align-center justify-end">
      <span class="font-quicksand">Filas por páginas: </span>
      <span style="width: 50px" class="mx-md-2">
        <v-select
          v-model="itemsPerPage"
          :items="itemsPerPages"
          class="font-quicksand"
        ></v-select>
      </span>
      <span class="font-quicksand"
        >1-{{ itemsPerPage }} de {{ users.totalItems }}
      </span>
      <v-pagination
        v-model="page"
        :length="pageCount"
        :total-visible="pageVisible"
        color="red"
        class="font-work-sans"
      ></v-pagination>
    </div>

    <dialog-user
      :user="user"
      :del="del"
      :dialog="dialog"
      @dismiss="
        () => {
          dialog = false
          loadData()
        }
      "
    />
  </div>
</template>

<script>
import DialogUser from './Dialog.vue'
import Repository from '@/repositories/RepositoryFactory'
const UserRepository = Repository.get('users')

export default {
  components: { DialogUser },
  name: 'ActiveUsers',
  props: ['search'],
  data: () => ({
    users: {},
    itemsPerPage: 7,
    pageVisible: 5,
    page: 1,
    pageCount: 0,
    headers: [
      {
        text: 'Cédula',
        align: 'center',
        class:
          'primary white--text rounded-tl-lg font-weight-bold text-subtitle-2',
        value: 'idNumber',
      },
      {
        text: 'Nombre',
        align: 'center',
        class: 'primary white--text font-weight-bold text-subtitle-2',
        value: 'firstName',
      },
      {
        text: 'Apellido',
        align: 'center',
        class: 'primary white--text font-weight-bold text-subtitle-2',
        value: 'lastName',
      },
      {
        text: 'Rol',
        align: 'center',
        class: 'primary white--text font-weight-bold text-subtitle-2',
        value: 'role',
      },
      {
        text: 'Opciones',
        align: 'center',
        class:
          'primary white--text rounded-tr-lg font-weight-bold text-subtitle-2',
        value: 'actions',
      },
    ],
    itemsPerPages: [7, 10, 20, 30, 50, 100],
    del: true,
    user: null,
    dialog: false,
  }),
  mounted: async function () {
    await this.loadData()
  },
  methods: {
    deleteUser: function (item) {
      this.user = item
      this.dialog = true
    },
    loadData: async function () {
      this.$eventBus.emit('loading-page')
      try {
        const res = await UserRepository.get()
        this.users = res.data.data
      } catch (error) {
        if (error.response?.status === 401) {
          localStorage.removeItem('user')
          location.reload()
        }

        const response = error.response ? error.response.data.message : error

        console.log(response)
      }
    },
  },
}
</script>
